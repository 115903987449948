<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ formatDateTime(itemDetail.start_date, "DD/MM/YYYY") }} -
      {{ formatDateTime(itemDetail.end_date, "DD/MM/YYYY") }}:
      {{ itemDetail.warehouse_code }} - {{ itemDetail.tracking_id }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <tbody>
            <tr class="text-center">
              <td>Người tạo</td>
              <td>Nội dung</td>
              <td>Kiểm kê</td>
              <td>Khách hàng</td>
              <td>Phương pháp</td>
              <!-- <td>Khớp SKU</td> -->
              <td
                rowspan="2"
                style="border-bottom: none"
                v-if="[2, 3].includes(itemDetail.status)"
              >
                <v-btn
                  v-if="itemDetail.status == 2"
                  color="primary"
                  small
                  class="mb-1"
                  style="width: 90px"
                  @click="showDialogConfirm('start')"
                >
                  {{ $t("labels.start") }}
                </v-btn>
                <br />
                <v-btn
                  v-if="itemDetail.status == 3"
                  color="error"
                  small
                  style="width: 90px"
                  @click="showDialogConfirm('stop')"
                >
                  {{ $t("labels.end") }}
                </v-btn>
              </td>
            </tr>
            <tr class="text-center">
              <td>{{ itemDetail.identity_name }}</td>
              <td>{{ itemDetail.name }}</td>
              <td>
                {{ $t(`labels.inventory_classify_${itemDetail.classify}`) }}
              </td>
              <td>{{ itemDetail.company_name }}</td>
              <td>{{ $t(`labels.inventory_type_${itemDetail.type}`) }}</td>
              <!-- <td>{{ itemDetail.inventory_sku }}/{{ itemDetail.request_sku }}</td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <tbody>
            <tr class="text-center">
              <td></td>
              <td>Thiếu</td>
              <td>Thừa</td>
              <td>Khớp</td>
              <td>Đã kiểm</td>
              <td>Chưa kiểm</td>
              <td>
                Cần kiểm <br />
                X = C + E + F
              </td>
              <td>
                Đang về <br />
                A
              </td>
              <td>
                Chờ lên kệ <br />
                B
              </td>
              <td>
                Chờ nhặt <br />
                C
              </td>
              <td>
                Đã nhặt <br />
                D
              </td>
              <td>
                Khả dụng <br />
                E
              </td>
              <td>
                Giữ tồn <br />
                F
              </td>
            </tr>
            <tr class="text-center">
              <td>SKU:</td>
              <td>{{ detailSum.missing.count_sku }}</td>
              <td>{{ detailSum.excess.count_sku }}</td>
              <td>{{ detailSum.match.count_sku }}</td>
              <td>{{ detailSum.inventory.count_sku }}</td>
              <td>{{ detailSum.notInventory.count_sku }}</td>
              <td v-for="(da, di) in detailSum.all" :key="di">
                {{ da.count_sku }}
              </td>
            </tr>
            <tr class="text-center">
              <td>Vị trí:</td>
              <td>{{ detailSum.missing.sum_location }}</td>
              <td>{{ detailSum.excess.sum_location }}</td>
              <td>{{ detailSum.match.sum_location }}</td>
              <td>{{ detailSum.inventory.sum_location }}</td>
              <td>{{ detailSum.notInventory.sum_location }}</td>
              <td v-for="(da, di) in detailSum.all" :key="di">
                {{ da.sum_location }}
              </td>
            </tr>
            <tr class="text-center">
              <td>S. Lượng:</td>
              <td>{{ detailSum.missing.sum_item }}</td>
              <td>{{ detailSum.excess.sum_item }}</td>
              <td>{{ detailSum.match.sum_item }}</td>
              <td>{{ detailSum.inventory.sum_item }}</td>
              <td>{{ detailSum.notInventory.sum_item }}</td>
              <td v-for="(da, di) in detailSum.all" :key="di">
                {{ da.sum_item }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text>
      <div class="fs-16px font-weight-medium black--text">Chi tiết</div>
      <custom-table
        ref="customTable"
        table-height="calc(100vh - 500px)"
        :columns="columns"
        :default-filters="{ id_inventory_request: itemDetail.id }"
        :footer-actions="footerActions"
        :is-page-show="true"
        api-url="/inventory/v1/detail-list"
        api-download-url="/inventory/v1/detail-export"
        :download-file-name="`kiem-ke-${itemDetail.tracking_id}.xlsx`"
        @showHistoryDialog="showHistoryDialog"
      ></custom-table>
    </v-card-text>

    <v-dialog v-model="historyDialog" max-width="640px">
      <InventoryHistory
        v-if="historyDialog"
        :sku-detail="skuDetail"
        @cancel="hideHistoryDialog"
      />
    </v-dialog>

    <v-dialog v-model="confirmDialog" persistent max-width="350px">
      <ConfirmBox
        v-if="confirmDialog"
        :title="$t(`labels.${confirmType}`)"
        :description="`Bạn có chắc chắn muốn ${$t(
          `labels.${confirmType}`
        )} lệnh kiểm kê không?`"
        label="Tracking"
        placeholder="Tracking"
        :codes="checkingCodes"
        @cancel="hideDialogConfirm"
        @confirm="onConfirmed"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  INVENTORY_DEFAULT,
  INVENTORY_DETAIL_STATUS_OPTIONS,
  INVENTORY_TYPE_OPTIONS,
  INVENTORY_CLASSIFY_OPTIONS,
} from "@/libs/const";

export default {
  name: "InventoryDetail",
  components: {
    InventoryHistory: () => import("@/components/goods/InventoryHistory"),
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
  },
  props: {
    itemDetail: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: { ...INVENTORY_DEFAULT },
    count: {},
    isLoading: false,
    historyDialog: false,
    confirmDialog: false,
    confirmType: null,
    skuDetail: {},
    customerOptions: [],
    statusOptions: [...INVENTORY_DETAIL_STATUS_OPTIONS],
    typeOptions: [...INVENTORY_TYPE_OPTIONS],
    classifyOptions: [...INVENTORY_CLASSIFY_OPTIONS],

    columns: [],
    footerActions: [],
    sums: [],
    detailSum: {
      missing: {},
      excess: {},
      match: {},
      inventory: {},
      notInventory: {},
      all: [],
    },
  }),
  computed: {
    checkingCodes() {
      return [this.itemDetail.tracking_id];
    },
  },
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: false,
        sortName: "sku",
        key: "sku",
        required: true,
        actionPermissions: [],
        actionModules: [],
        action: "showHistoryDialog",
      },
      {
        type: "input-filter",
        label: this.$t("labels.position_need_check"),
        placeholder: this.$t("labels.position_need_check"),
        name: "request_location",
        hasSort: false,
        sortName: "request_location",
        key: "request_location",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.position_checked"),
        placeholder: this.$t("labels.position_checked"),
        name: "inventory_location",
        hasSort: false,
        sortName: "inventory_location",
        key: "inventory_location",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.need_check"),
        placeholder: this.$t("labels.need_check"),
        name: "request_item",
        hasSort: false,
        sortName: "request_item",
        key: "request_item",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.checked"),
        placeholder: this.$t("labels.checked"),
        name: "inventory_item",
        hasSort: false,
        sortName: "inventory_item",
        key: "inventory_item",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.sub_quantity"),
        placeholder: this.$t("labels.sub_quantity"),
        name: "sub_item",
        hasSort: false,
        sortName: "sub_item",
        key: "sub_item",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {
    this.getSum();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    showHistoryDialog(item) {
      this.skuDetail = { ...item };
      this.historyDialog = true;
    },
    hideHistoryDialog() {
      this.skuDetail = {};
      this.historyDialog = false;
    },
    showDialogConfirm(type) {
      this.confirmType = type;
      this.confirmDialog = true;
    },
    hideDialogConfirm() {
      this.confirmType = null;
      this.confirmDialog = false;
    },
    async onConfirmed() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post(
          `/inventory/v1/${this.confirmType}`,
          this.itemDetail
        );
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.$vToastify.success(this.$t("messages.create_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    async getSum() {
      try {
        const { data } = await httpClient.post(`/inventory/v1/detail-sum`, {
          id_inventory_request: this.itemDetail.id,
        });
        this.detailSum = { ...data };
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>

<style scoped></style>
